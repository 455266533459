/* eslint-disable no-useless-escape */
/* eslint-disable no-unsafe-optional-chaining */
/* global Word */

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import bayonConfig from '@core/bayon.config';

import getHeaders from '@bayon/fetch/dist/Apollo/utils/getHeaders';
import { useTranslation } from '@bayon/i18n';
import { useQuery } from '@bayon/fetch';

import {
  CONVERT_PIPELINE,
  cleanLocalFields,
  getDocumentByteArray,
  getJSONField,
  setJSONField,
} from '../utils';
import { useCustomProperties } from '../hooks';
import { FIELD_ORIGIN, FIELD_STATUS } from '../constants';

export const WordContext = React.createContext();

export function WordProvider({ children }) {
  const { t } = useTranslation('editorWordAddin');

  const [errors, setErrors] = useState(null);

  const { Authorization, tenant, lotacao } = getHeaders();
  const { customProperties, baseUrl } = useCustomProperties();

  const { refetch } = useQuery(bayonConfig.queries.validate, {
    variables: { filter: { id: customProperties?.blobid } },
    context: {
      headers: {
        Authorization,
        ...tenant,
        lotacao,
      },
    },
    skip: !customProperties?.blobid,
  });

  const getDocumentTags = async ({ fields = [] } = {}) => {
    return Word.run(async (context) => {
      const { contentControls } = context?.document;

      contentControls.load(
        [...new Set(['id', 'tag', 'title', 'text', ...fields])].join(',')
      );

      await context.sync();

      return contentControls?.items;
    });
  };

  const updateFieldsStatus = async () => {
    const fields = await getDocumentTags();

    const errorsFields = fields.reduce((acc, field) => {
      if (!field.tag) {
        return acc;
      }

      const fieldStatus = getJSONField(field.tag, 'status');
      const fieldName = getJSONField(field.tag, 'nome');

      return [FIELD_STATUS.ERRO, FIELD_STATUS.VAZIO].includes(fieldStatus)
        ? [...acc, fieldName]
        : acc;
    }, []);

    if (errorsFields.length > 0) {
      setErrors(errorsFields);
      return;
    }

    setErrors(null);
  };

  const configTagProperties = ({ contentControl, field, parsedCode }) => {
    contentControl.appearance = Word.ContentControlAppearance.boundingBox;
    contentControl.title = `${field.nome} (${t('TO_DELETE_FIELDS')})`;
    if (parsedCode) {
      const current_font_color = getJSONField(parsedCode, 'current_font_color');
      contentControl.tag = parsedCode;
      if (current_font_color && current_font_color !== '000000') {
        contentControl.color = current_font_color;
      }
    }

    const text = contentControl.insertText(
      field?.values?.length === 1 ? field?.values[0] : field.nome,
      Word.InsertLocation.replace
    );

    if (parsedCode && text) {
      text.font.highlightColor =
        getJSONField(parsedCode, 'current_highlight_color') ?? null;
    }

    contentControl.cannotDelete = true;
    contentControl.cannotEdit = [
      FIELD_STATUS.VAZIO,
      FIELD_STATUS.ERRO,
    ].includes(getJSONField(parsedCode, 'status'));
  };

  const removeTag = async (id) => {
    return Word.run(async (context) => {
      const contentControl = context.document.contentControls.getById(id);
      contentControl.load('id,tag,title,text,cannotDelete');

      await context.sync();

      contentControl.cannotDelete = false;

      await context.sync();

      contentControl.delete(false);

      await context.sync();

      await updateFieldsStatus();

      return context.sync();
    });
  };

  const updateTag = (id, value) => {
    return Word.run(async (context) => {
      const contentControl = context.document.contentControls.getById(id);
      contentControl.load('id,tag,title,text,cannotDelete');

      await context.sync();

      contentControl.cannotDelete = false;

      const text = contentControl.insertText(
        value,
        Word.InsertLocation.replace
      );

      if (contentControl.tag) {
        let newTag = contentControl.tag;
        const fieldStatus = getJSONField(newTag, 'status');

        if (fieldStatus === FIELD_STATUS.VARIOS) {
          newTag = setJSONField(newTag, 'status', FIELD_STATUS.SUCESSO);
          text.font.highlightColor = null;
        }

        contentControl.tag = newTag;
      }

      contentControl.cannotDelete = true;

      await context.sync();

      await updateFieldsStatus();

      return context.sync();
    });
  };

  const highlightTag = async (id, focus) => {
    return Word.run(async (context) => {
      const contentControl = context.document.contentControls.getById(id);
      contentControl.load('id,tag,title,text,color');
      await context.sync();

      contentControl.getRange().select(focus ? 'Select' : 'End');

      return context.sync();
    });
  };

  const getTagStatus = (values) => {
    switch (values?.length) {
      case undefined:
      case null:
        return FIELD_STATUS.ERRO;
      case 0:
        return FIELD_STATUS.VAZIO;
      case 1:
        return FIELD_STATUS.SUCESSO;
      default:
        return FIELD_STATUS.VARIOS;
    }
  };

  const addTag = async ({ type, fieldId, nome, descricao, values = [] }) => {
    return Word.run(async (context) => {
      try {
        let selection = context.document.getSelection();

        await context.sync();

        const contentControl = selection.insertContentControl();
        context.load(contentControl, 'id');

        await context.sync();

        configTagProperties({
          contentControl,
          field: {
            id: fieldId,
            nome,
            type,
            values,
          },
          parsedCode: JSON.stringify({
            nome,
            tipo_campo: type,
            campo_id: fieldId,
            descricao,
            status: getTagStatus(values),
            origem: FIELD_ORIGIN.MERGEFIELD,
          }),
        });

        await context.sync();

        return { id: contentControl.id, nome, values };
      } catch {
        return null;
      }
    });
  };

  const getAllDocumentFields = async (context) => {
    const sections = context.document.sections;
    const sectionItems = sections.load('items');

    await context.sync();

    const fields = context.document.body.fields.load('items');

    const headersAndFootersItems = sectionItems.items.reduce((acc, section) => {
      for (let type of Object.keys(Word.HeaderFooterType)) {
        acc.push(section.getHeader(type)?.fields.load('items'));
        acc.push(section.getFooter(type)?.fields.load('items'));
      }

      return acc;
    }, []);

    await context.sync();

    return [
      ...fields.items,
      ...headersAndFootersItems.flatMap((item) => {
        return item.items ?? [];
      }),
    ];
  };

  const getUnmergeDocumentFields = async (mappedFields, getFieldOptions) => {
    return Word.run(async (context) => {
      const fields = await getAllDocumentFields(context);

      const errors = new Set();

      const localContext = {
        context,
        fields,
        createdFields: [],
        mappedFields,
        getFieldOptions,
        configTagProperties,
        contentControls: [],
        parsedCodes: [],
        validFields: [],
      };

      for (const step of CONVERT_PIPELINE) {
        for (let i = 0; i < fields.length; i++) {
          try {
            if (errors.has(i)) {
              continue;
            }

            await step.function(i, localContext);
          } catch (err) {
            errors.add(i);
            continue;
          }
        }

        if (step.autoSync) {
          await context.sync();
        }
      }
      return localContext.createdFields;
    });
  };

  const saveDocument = async () => {
    const byteArray = await getDocumentByteArray();
    const file = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    const myHeaders = new Headers();
    myHeaders.append('Authorization', Authorization);
    myHeaders.append('tenant', tenant?.tenant);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('blobId', customProperties?.blobid);

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formData,
    };

    const response = await fetch(`${baseUrl}/documento/salvar`, requestOptions);

    cleanLocalFields();
    return response;
  };

  const validateSave = async () => {
    const lotacaoStorage = localStorage.getItem('lotacao');
    const tenantStorage = localStorage.getItem('tenant');
    const tokenStorage = localStorage.getItem('token');

    const { data } = await refetch();

    if (!data.usuario_revisor_autorizado_alterar_documento) {
      return { valid: false, save: true };
    }

    try {
      const response = await fetch(
        `${baseUrl}/documento/obter_metadados_dinamicos?blobid=${customProperties?.blobid}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokenStorage}`,
            tenant: tenantStorage,
            lotacao: lotacaoStorage,
          },
        }
      );

      if (!response.ok) {
        return { save: false, valid: true };
      }

      const body = await response.json();

      return { ...body, save: true, valid: true };
    } catch (e) {
      return { save: false, valid: true };
    }
  };

  return (
    <WordContext.Provider
      value={{
        highlightTag,
        removeTag,
        updateTag,
        addTag,
        saveDocument,
        getDocumentTags,
        customProperties,
        getUnmergeDocumentFields,
        errors,
        validateSave,
        updateFieldsStatus,
      }}
    >
      {children}
    </WordContext.Provider>
  );
}

WordProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
